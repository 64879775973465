<template>
  <div id="displayQuestions" class="w-100">
    <v-snackbar color="primary" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <!-- Question dialog -->
    <v-dialog v-model="questionDialog" transition="dialog-bottom-transition">
      <v-card tile v-if="selectedQuestion">
        <!-- Toolbar -->
        <v-toolbar dense dark color="pink darken-1">
          <v-btn icon dark @click="questionDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="hidden-sm-and-down">Question</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!-- Delete Modal Button -->
            <v-btn
              @click="deleteQuestionModal(selectedQuestion.arrayPos)"
              dark
              text
              class="mr-1"
              v-if="!enableSelect"
              small
            >
              <v-icon class="mr-1">mdi-trash-can-outline mdi-18px</v-icon>
              Delete
            </v-btn>

            <!-- Edit Button -->
            <v-btn
              :to="{
                name: 'createQuestion',
                params: { questionObj: selectedQuestion },
              }"
              dark
              text
              class="ml-1"
              small
            >
              <v-icon class="mr-1">mdi-square-edit-outline mdi-18px</v-icon>
              Edit
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <!-- Error Row -->
        <div v-if="error" class="mt-5 mb-n3 px-1 px-md-3">
          <v-alert type="error">{{ error }}</v-alert>
        </div>

        <!-- Question MetaData -->
        <v-card-subtitle class="mt-5 mt-md-9">
          <v-chip class="mx-1">
            <b class="mr-1">ID:</b> {{ selectedQuestion.id }}
          </v-chip>

          <v-chip class="mx-1">
            <b class="mr-1">Course:</b> {{ selectedQuestion.course }}
          </v-chip>

          <v-chip class="mx-1">
            <b class="mr-1">Folder:</b> {{ selectedQuestion.folder }}
          </v-chip>

          <v-chip class="mx-1">
            <b class="ml-2 mr-1"> Bi-lingual:</b>
            {{ selectedQuestion.isBilingual ? "Yes" : "No" }}
          </v-chip>
        </v-card-subtitle>

        <v-card-subtitle class="pb-1 black--text"> Question: </v-card-subtitle>

        <v-card-text>
          <!-- Question -->
          <div>
            <div>
              <small class="text-caption"> Primary Language:</small>
            </div>
            <span
              class="text-h6"
              v-html="selectedQuestion.question.Primary"
            ></span>

            <v-divider class="mb-2"></v-divider>

            <div>
              <small class="text-caption">Secondary Language:</small>
            </div>
            <span
              class="text-h6"
              v-html="selectedQuestion.question.Secondary"
            ></span>

            <v-divider class="my-2"></v-divider>
          </div>

          <v-container class="pa-2" fluid>
            <!-- Options -->
            <div class="mb-6">
              <v-row class="mb-2">
                <b>Options:</b>
              </v-row>
              <v-row
                align="center"
                v-for="(option, i) in selectedQuestion.optionsData"
                :key="i"
              >
                <v-col cols="12" sm="12" class="d-flex">
                  <!-- Check Icon -->
                  <span class="mr-2 d-flex align-md-center align-start">
                    <v-icon
                      :color="
                        selectedQuestion.correctAnswer == i ? 'green' : 'grey'
                      "
                      class="mr-2"
                      style="margin-top: 0.8px"
                    >
                      mdi-check-circle mdi-18px
                    </v-icon>
                  </span>

                  <!-- Option Content -->
                  <div>
                    <div>
                      <small class="text-caption"> Primary Language:</small>
                      <span v-html="option.Primary"></span>
                    </div>
                    <v-divider class="mb-2"></v-divider>
                    <div>
                      <small class="text-caption"> Secondary Language:</small>
                      <span v-html="option.Secondary"></span>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-divider class="mt-2"></v-divider>
            </div>

            <!-- Solution -->
            <v-row>
              <b>Solution:</b>
            </v-row>
            <v-row align="center">
              <v-col cols="12" sm="10">
                <div>
                  <small class="text-caption"> Primary Language:</small>
                  <span v-html="selectedQuestion.solution.Primary"></span>
                </div>

                <v-divider class="my-2"></v-divider>

                <div>
                  <small class="text-caption"> Secondary Language:</small>
                  <span v-html="selectedQuestion.solution.Secondary"></span>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Delete dialog -->
    <v-dialog v-model="deleteDialog" persistent max-width="590">
      <v-card :loading="loading || superLoading">
        <v-card-title class="headline">
          Are you sure you want to delete this question ?
        </v-card-title>
        <v-card-subtitle class="mt-1">
          (Question ID: {{ selectedQuestion.id }})
        </v-card-subtitle>
        <v-card-text>
          This action cannot be reversed. Please click the "DELETE" button to
          confirm.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            dark
            :loading="loading || superLoading"
            @click="deleteQuestion"
          >
            <v-icon class="mr-1">mdi-trash-can-outline</v-icon> Delete
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="loading || superLoading"
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <h6 class="text-h6 pt-4">
      <v-icon class="mr-1">mdi-chevron-right</v-icon> Questions:
    </h6>

    <!-- Display container with error, questions display, nav buttons -->
    <v-container class="mt-4 pa-0 pa-md-4">
      <!-- Error Row -->
      <v-row align="center" no-gutters v-if="error">
        <v-col cols="12" class="mt-2">
          <v-alert type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>

      <!-- Navigation Buttons Row -->
      <v-row class="mb-6" align="center" no-gutters>
        <v-col cols="12" sm="12" class="d-flex justify-center">
          <v-btn
            class="mx-1"
            color="pink darken-1"
            :loading="loading || superLoading"
            @click="fetchQuestions('prev')"
            text
          >
            <v-icon>mdi-chevron-left</v-icon> Previous
          </v-btn>
          <v-btn
            class="mx-1"
            color="pink darken-1"
            :loading="loading || superLoading"
            @click="fetchQuestions('next')"
            text
          >
            Next
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <!-- Display Questions Row -->
      <v-row align="center" justify="center" no-gutters>
        <v-col
          cols="12"
          sm="12"
          lg="9"
          v-for="(question, i) in allQuestions"
          :key="i"
        >
          <div class="d-flex">
            <v-checkbox
              v-if="enableSelect"
              label="Add Question"
              v-model="selectedQuestionsLocal"
              :value="question.id"
              :key="question.id"
              @change="emitSelectedQuestions"
              multiple
            ></v-checkbox>

            <!-- Question Card -->
            <v-card
              class="ma-1 w-100"
              color="grey darken-3"
              dark
              outlined
              elevation="0"
              :disabled="loading || superLoading"
              @click="openQuestionModal(i)"
            >
              <!-- ID, Bilingual, Folder and Course -->
              <v-card-subtitle class="my-n2">
                <!-- TODO add no-wrap-text -->
                <span class="d-md-flex align-center text-capitalize">
                  <v-chip small class="ma-1">
                    <b class="pink--text text--lighten-3 mr-1">ID:</b>
                    {{ question.id }}
                  </v-chip>

                  <v-chip small class="ma-1">
                    <b class="mr-1 pink--text text--lighten-3"> Course:</b>
                    {{ question.course ? `'${question.course}'` : "None" }}
                  </v-chip>

                  <v-chip small class="ma-1">
                    <b class="mr-1 pink--text text--lighten-3"> Folder:</b>
                    {{ question.folder ? `'${question.folder}'` : "None" }}
                  </v-chip>

                  <v-chip small class="ma-1">
                    <b class="mr-1 pink--text text--lighten-3"> Bi-lingual:</b>
                    {{ question.isBilingual ? "Yes" : "No" }}
                  </v-chip>

                  <v-spacer></v-spacer>
                </span>
              </v-card-subtitle>

              <v-divider class="mb-3"></v-divider>

              <!-- Question text-->
              <v-card-subtitle
                class="mt-5 pt-0 pb-1 pink--text text--lighten-3"
              >
                Question:
              </v-card-subtitle>

              <v-card-text class="text-h6 ml-2">
                <div>
                  <small class="text-caption"> Primary Language:</small>
                </div>
                <span v-html="question.question.Primary"></span>

                <v-divider></v-divider>

                <div>
                  <small class="text-caption">Secondary Language:</small>
                </div>
                <span v-html="question.question.Secondary"></span>
              </v-card-text>

              <v-divider v-if="!enableSelect" class="mb-2"></v-divider>

              <!-- Delete and Edit Buttons -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="ml-md-1 ml-n1 mr-1"
                  color="pink lighten-1"
                  v-if="!enableSelect"
                  @click="deleteQuestionModal(i)"
                  :disabled="loading || superLoading"
                  outlined
                  small
                >
                  <v-icon class="mr-1">mdi-trash-can-outline mdi-18px</v-icon>
                  Delete
                </v-btn>
                <v-btn
                  :to="{
                    name: 'editQuestion',
                    params: { id: question.id },
                  }"
                  target="_blank"
                  :disabled="loading || superLoading"
                  color="pink lighten-1"
                  class="mx-1"
                  small
                >
                  <v-icon class="mr-1">mdi-square-edit-outline mdi-18px</v-icon>
                  Edit
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>

      <!-- Navigation Buttons Row -->
      <v-row class="mt-9" align="center" no-gutters>
        <v-col cols="12" sm="12" class="d-flex justify-center">
          <v-btn
            class="mx-1"
            color="pink darken-1"
            :loading="loading || superLoading"
            @click="fetchQuestions('prev')"
            text
          >
            <v-icon>mdi-chevron-left</v-icon> Previous
          </v-btn>
          <v-btn
            class="mx-1"
            color="pink darken-1"
            :loading="loading || superLoading"
            @click="fetchQuestions('next')"
            text
          >
            Next
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// TODO: add watcher for allQuestions and rerender
export default {
  name: "DisplayQuestions",
  props: ["superLoading", "allQuestions", "enableSelect", "selectedQuestions"],
  watch: {
    //copy selectedQuestions to local data when change by parent data (for edit test)
    selectedQuestions: function (newVal) {
      this.selectedQuestionsLocal = newVal;
    },
  },
  data: () => ({
    loading: true,
    snackbar: false,
    snackbarText: "",
    error: "",
    questionDialog: false,
    deleteDialog: false,
    selectedQuestion: "",
    selectedQuestionsLocal: [],
  }),
  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    fetchQuestions(value) {
      this.$emit("fetchQuestions", value);
    },

    openQuestionModal(index) {
      this.selectedQuestion = this.allQuestions[index];
      this.selectedQuestion.arrayPos = index;
      if (!this.deleteDialog) {
        this.questionDialog = true;
      }

      // this.$nextTick().then(() => {
      //   this.reRender();
      // });
    },

    // reRender() {
    //   if (window.MathJax) {
    //     console.log("rendering mathjax");
    //     window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () =>
    //       console.log("mathjax render complete")
    //     );
    //   }
    // },

    deleteQuestionModal(index) {
      this.selectedQuestion = this.allQuestions[index];
      this.selectedQuestion.arrayPos = index;
      this.deleteDialog = true;
    },

    deleteQuestion() {
      this.setLoading(true);

      const payload = {
        id: this.selectedQuestion.id,
        folder: this.selectedQuestion.folder,
      };

      this.$store
        .dispatch("deleteQuestion", payload)
        .then(() => {
          this.snackbarText = "Question deleted successfully :)";
          //remove from allQuestions array
          this.$emit("removeQuestion", this.selectedQuestion.arrayPos);
          this.deleteDialog = false;
          this.questionDialog = false;
        })
        .catch((err) => {
          this.error = err;
          this.snackbarText = "Error, please try again :(";
        })
        .finally(() => {
          this.snackbar = true;
          this.setLoading(false);
        });
    },

    //enableSelect Methods
    emitSelectedQuestions() {
      this.$emit("questionsSelected", this.selectedQuestionsLocal);
    },
  },
  mounted() {
    if (this.enableSelect) {
      this.selectedQuestionsLocal = this.selectedQuestions;
    }

    this.setLoading(false);

    // this.$nextTick().then(() => {
    //   this.reRender();
    // });
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
}
</style>