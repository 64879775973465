<template>
  <div id="questionsCard" class="w-100">
    <v-snackbar color="primary" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-card
      :loading="loading || superLoading"
      :elevation="enableSelect ? 0 : 3"
      class="w-100 pb-4 px-1 px-md-4"
    >
      <!-- Title -->
      <h6 class="text-h6 pt-4" v-if="!enableSelect">
        <v-icon class="mr-1">mdi-chevron-right</v-icon> Fetch Questions:
      </h6>

      <v-container>
        <!-- Error Row -->
        <v-row align="center" no-gutters v-if="error">
          <v-col cols="12" class="mt-2">
            <v-alert type="error">{{ error }}</v-alert>
          </v-col>
        </v-row>

        <!-- Fetch Questions Row -->
        <v-row justify="center" align="center">
          <!-- All Questions Column -->
          <v-col cols="12" sm="12" md="4">
            <v-btn
              class="mx-auto"
              color="pink darken-1"
              dark
              :loading="loading || superLoading"
              @click="getAllQuestions(null)"
              large
              block
              outlined
            >
              <v-icon class="mr-2">mdi-text-box-multiple</v-icon>
              Show All Questions
            </v-btn>
          </v-col>

          <!-- Selected Questions Column -->
          <v-col
            v-if="
              enableSelect &&
              selectedQuestionsLocal &&
              selectedQuestionsLocal.length > 0
            "
            cols="12"
            sm="12"
            md="4"
          >
            <v-btn
              class="mx-auto"
              color="pink darken-1"
              dark
              :loading="loading || superLoading"
              @click="getSelectedQuestions(null)"
              large
              outlined
            >
              <v-icon class="mr-2">mdi-checkbox-multiple-marked</v-icon>
              Show Selected Questions
            </v-btn>
          </v-col>

          <!-- Show by folder select column -->
          <v-col cols="12" sm="12" md="4">
            <v-select
              label="or Browse folder"
              class="text-capitalize mt-7"
              color="pink darken-1"
              v-model="selectedFolder"
              @change="getFolderQuestions"
              :items="questionFolders"
              :disabled="loading || superLoading"
              outlined
              dense
            ></v-select>
          </v-col>

          <!-- Show by course select column -->
          <v-col cols="12" sm="12" md="4">
            <v-select
              label="or Browse course"
              class="text-capitalize mt-7"
              color="pink darken-1"
              v-model="selectedCourse"
              @change="getCourseQuestions"
              :items="courses"
              item-text="courseName"
              :disabled="loading || superLoading"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>

        <v-divider class="mb-2"></v-divider>

        <!-- Display Questions Row -->
        <v-row
          justify="center"
          align="center"
          v-if="allQuestions && allQuestions.length > 0"
        >
          <DisplayQuestions
            :superLoading="superLoading"
            @setSuperLoading="setLoading"
            :enableSelect="enableSelect"
            :allQuestions="allQuestions"
            :selectedQuestions="selectedQuestions"
            @fetchQuestions="fetchQuestions"
            @removeQuestion="removeQuestion"
            @questionsSelected="emitSelectedQuestions"
          />
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import DisplayQuestions from "@/components/Home/Manage/QuestionBank/DisplayQuestions.vue";

export default {
  name: "QuestionsCard",
  props: ["superLoading", "enableSelect", "selectedQuestions"],
  components: { DisplayQuestions },
  watch: {
    //copy selectedQuestions to local data when change by parent data (for edit test)
    selectedQuestions: function (newVal) {
      this.selectedQuestionsLocal = newVal;
    },
  },
  data: () => ({
    loading: true,
    snackbar: false,
    snackbarText: "",
    error: "",
    questionFolders: [],
    selectedFolder: "",
    courses: [],
    selectedCourse: "",
    allQuestions: [],
    firstAndLastVisible: null,
    selectedQuestionsLocal: [],
  }),
  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    fetchFolders() {
      this.setLoading(true);
      //get folder names from server
      this.$store
        .dispatch("getQuestionFolders")
        .then((res) => {
          if (res) {
            this.questionFolders = res.folderNames;
          }
        })
        .catch((err) => {
          this.error = err;
        })
        .finally(() => {
          this.setLoading(false);
          this.dialog = false;
        });
    },

    fetchCourses() {
      this.$store
        .dispatch("getMetaDoc")
        .then((res) => {
          if (res) {
            this.courses = res.courses;
          }
        })
        .catch(() => {
          this.error =
            "Network error in fetching courses, please refresh and try again :(";
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getAllQuestions(requestType) {
      var payload = "";

      switch (requestType) {
        case "prev":
          payload = { requestType, doc: this.firstAndLastVisible.firstVisible };
          break;
        case "next":
          payload = { requestType, doc: this.firstAndLastVisible.lastVisible };
          break;
        default:
          payload = { requestType: null };
      }

      this.setLoading(true);
      this.selectedFolder = "";
      this.selectedCourse = "";
      this.$store
        .dispatch("getAllQuestions", payload)
        .then((res) => {
          // write data if there is data
          if (res.data.length > 0) {
            this.snackbarText = "Questions fetched successfully :)";
            this.allQuestions = res.data;
            this.firstAndLastVisible = res.firstAndLastVisible;
          } else {
            //if there is no data show on snackbar accordingly
            switch (requestType) {
              case "prev":
                this.snackbarText = "This is the first page.";
                break;
              case "next":
                this.snackbarText = "This is the last page.";
                break;
              default:
                this.snackbarText = "You've not added any questions yet :(";
                this.allQuestions = [];
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error;
          this.snackbarText = "Error, please try again :(";
        })
        .finally(() => {
          this.snackbar = true;
          this.setLoading(false);
        });
    },

    getSelectedQuestions() {
      const payload = this.selectedQuestionsLocal;

      this.setLoading(true);
      this.selectedFolder = "";
      this.selectedCourse = "";
      this.$store
        .dispatch("getSelectedQuestions", payload)
        .then((res) => {
          // write data if there is data
          if (res.length > 0) {
            this.snackbarText = "Questions fetched successfully :)";
            this.allQuestions = res;
          } else {
            //if there is no data show on snackbar accordingly
            this.snackbarText = "You've not selected any questions yet :(";
            this.allQuestions = [];
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error;
          this.snackbarText = "Error, please try again :(";
        })
        .finally(() => {
          this.snackbar = true;
          this.setLoading(false);
        });
    },

    getFolderQuestions(requestType) {
      var payload = "";

      switch (requestType) {
        case "prev":
          payload = {
            requestType,
            doc: this.firstAndLastVisible.firstVisible,
            folder: this.selectedFolder,
          };
          break;
        case "next":
          payload = {
            requestType,
            doc: this.firstAndLastVisible.lastVisible,
            folder: this.selectedFolder,
          };
          break;
        default:
          payload = { requestType: null, folder: this.selectedFolder };
      }

      this.setLoading(true);

      this.selectedCourse = "";

      this.$store
        .dispatch("getFolderQuestions", payload)
        .then((res) => {
          // write data if there is data
          if (res.data.length > 0) {
            this.snackbarText = "Questions fetched successfully :)";
            this.allQuestions = res.data;
            this.firstAndLastVisible = res.firstAndLastVisible;
          } else {
            //if there is no data show on snackbar accordingly
            switch (requestType) {
              case "prev":
                this.snackbarText = "This is the first page.";
                break;
              case "next":
                this.snackbarText = "This is the last page.";
                break;
              default:
                this.snackbarText =
                  "You've not added any questions to this folder yet :(";
                this.allQuestions = [];
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error;
          this.snackbarText = "Error, please try again :(";
        })
        .finally(() => {
          this.snackbar = true;
          this.setLoading(false);
        });
    },

    getCourseQuestions(requestType) {
      var payload = "";

      switch (requestType) {
        case "prev":
          payload = {
            requestType,
            doc: this.firstAndLastVisible.firstVisible,
            course: this.selectedCourse,
          };
          break;
        case "next":
          payload = {
            requestType,
            doc: this.firstAndLastVisible.lastVisible,
            course: this.selectedCourse,
          };
          break;
        default:
          payload = { requestType: null, course: this.selectedCourse };
      }

      this.setLoading(true);

      this.selectedFolder = "";

      this.$store
        .dispatch("getCourseQuestions", payload)
        .then((res) => {
          // write data if there is data
          if (res.data.length > 0) {
            this.snackbarText = "Questions fetched successfully :)";
            this.allQuestions = res.data;
            this.firstAndLastVisible = res.firstAndLastVisible;
          } else {
            //if there is no data show on snackbar accordingly
            switch (requestType) {
              case "prev":
                this.snackbarText = "This is the first page.";
                break;
              case "next":
                this.snackbarText = "This is the last page.";
                break;
              default:
                this.snackbarText =
                  "You've not added any questions to this course yet :(";
                this.allQuestions = [];
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error;
          this.snackbarText = "Error, please try again :(";
        })
        .finally(() => {
          this.snackbar = true;
          this.setLoading(false);
        });
    },

    fetchQuestions(requestType) {
      if (this.selectedFolder) {
        this.getFolderQuestions(requestType);
      } else if (this.selectedCourse) {
        this.getCourseQuestions(requestType);
      } else {
        this.getAllQuestions(requestType);
      }
    },

    removeQuestion(arrayPos) {
      //remove question from allQuestions array
      this.allQuestions.splice(arrayPos, 1);
      this.setLoading(false);
    },

    emitSelectedQuestions(selectedQuestions) {
      this.selectedQuestionsLocal = selectedQuestions;
      this.$emit("questionsSelected", selectedQuestions);
    },
  },
  mounted() {
    if (this.enableSelect) {
      this.selectedQuestionsLocal = this.selectedQuestions;
    }
    this.fetchFolders();
    this.fetchCourses();
  },
};
</script>
